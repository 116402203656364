// src/store/slices/cloudSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../../services/api';
import { FileMetadata, Drawing, Folder } from '../models';
import { handleAPIError, createThunkTemplate } from '../../services/apiUtils';
import createLogger from '../../logger';

const logger = createLogger('cloudSlice.ts', { minLevel: 'debug' });

interface FolderState {
    currentFolder: Folder | null;
    subfolders: Folder[];
    drawings: Drawing[];
    files: FileMetadata[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    folderHierarchy: Folder[];
}

const initialState: FolderState = {
    currentFolder: null,
    subfolders: [],
    drawings: [],
    files: [],
    status: 'idle',
    error: null,
    folderHierarchy: [],
};

// Async thunks
export const createFolder = createThunkTemplate<{message: string}, { name: string; parentId: number | null; isProject: boolean }>(
    'folders/createFolder',
    api.createFolderAPI
);


export const fetchFolderContents = createThunkTemplate<
    { folder: Folder; subfolders: Folder[]; drawings: Drawing[]; files: FileMetadata[], message: string },
    number
>(
    'folders/fetchFolderContents',
    api.fetchFolderContentsAPI
);

export const fetchHomeFolder = createThunkTemplate<
    { folder: Folder; subfolders: Folder[]; drawings: Drawing[]; files: FileMetadata[], message: string },
    void
>(
    'folders/fetchHomeFolder',
    api.fetchHomeFolderAPI
);

export const fetchFolderHierarchy = createThunkTemplate<Folder[], number>(
    'folders/fetchFolderHierarchy',
    api.getFolderHierarchyAPI
);

export const uploadFile = createThunkTemplate<{message: string}, { file: File; folderId: number }>(
    'folders/uploadFile',
    api.uploadFileAPI
);

export const deleteFile = createThunkTemplate<{message: string}, number>(
    'folders/deleteFile',
    api.deleteFileAPI
);

export const renameFile = createThunkTemplate<{message: string}, { fileId: number; newName: string }>(
    'folders/renameFile',
    api.renameFileAPI
);

export const deleteDrawing = createThunkTemplate<{message: string}, number>(
    'folders/deleteDrawing',
    api.deleteDrawingAPI
);

export const renameDrawing = createThunkTemplate<{message: string}, { drawingId: number; newName: string }>(
    'folders/renameDrawing',
    api.renameDrawingAPI
);

export const deleteFolder = createThunkTemplate<{message: string}, number>(
    'folders/deleteFolder',
    api.deleteFolderAPI
);

export const renameFolder = createThunkTemplate<{message: string}, { folderId: number; newName: string }>(
    'folders/renameFolder',
    api.renameFolderAPI
);

export const downloadFile = createThunkTemplate<
    { blob: Blob; headers: any},
    number
>(
    'folders/downloadFile',
    api.downloadFileAPI
);

export const downloadDrawingFile = createThunkTemplate<
    { blob: Blob; headers: any },
    { drawingId: number; version: number }
>(
    'folders/downloadDrawingFile',
    api.downloadDrawingFileAPI
);

const cloudSlice = createSlice({
    name: 'cloud',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(createFolder.pending, (state) => {
                state.status = 'loading';
                logger.info('Creating folder started');
            })
            .addCase(createFolder.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Folder creation succeeded', action.payload);
            })
            .addCase(createFolder.rejected, (state, action) => {
                handleAPIError('Folder creation failed', logger)(state, action);
            })

            .addCase(fetchFolderContents.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching folder contents started');
            })
            .addCase(fetchFolderContents.fulfilled, (state, action: PayloadAction<{ folder: Folder; subfolders: Folder[]; drawings: Drawing[]; files: FileMetadata[], message: string }>) => {
                state.currentFolder = action.payload.folder;
                state.subfolders = action.payload.subfolders;
                state.drawings = action.payload.drawings;
                state.files = action.payload.files;
                state.status = 'succeeded';
                state.error = null;
                logger.info('Fetching folder contents succeeded', action.payload);
            })
            .addCase(fetchFolderContents.rejected, handleAPIError('Fetching folder contents failed', logger))

            .addCase(fetchHomeFolder.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching home folder started');
            })
            .addCase(fetchHomeFolder.fulfilled, (state, action: PayloadAction<{ folder: Folder; subfolders: Folder[]; drawings: Drawing[]; files: FileMetadata[], message: string }>) => {
                state.currentFolder = action.payload.folder;
                state.subfolders = action.payload.subfolders;
                state.drawings = action.payload.drawings;
                state.files = action.payload.files;
                state.status = 'succeeded';
                state.error = null;
                logger.info('Fetching home folder succeeded', action.payload);
            })
            .addCase(fetchHomeFolder.rejected, handleAPIError('Fetching home folder failed', logger))

            .addCase(fetchFolderHierarchy.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching folder hierarchy started');
            })
            .addCase(fetchFolderHierarchy.fulfilled, (state, action: PayloadAction<Folder[]>) => {
                state.folderHierarchy = action.payload;
                state.status = 'succeeded';
                state.error = null;
                logger.info('Fetching folder hierarchy succeeded', { folderHierarchy: action.payload });
            })
            .addCase(fetchFolderHierarchy.rejected, handleAPIError('Fetching folder hierarchy failed', logger))

            .addCase(uploadFile.pending, (state) => {
                state.status = 'loading';
                logger.info('Uploading file started');
            })
            .addCase(uploadFile.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('File upload succeeded', action.payload);
            })
            .addCase(uploadFile.rejected, (state, action) => {
                handleAPIError('File upload failed', logger)(state, action);
            })

            .addCase(deleteFile.pending, (state) => {
                state.status = 'loading';
                logger.info('Deleting file started');
            })
            .addCase(deleteFile.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('File deletion succeeded');
            })
            .addCase(deleteFile.rejected, (state, action) => {
                handleAPIError('File deletion failed', logger)(state, action);
            })

            .addCase(renameFile.pending, (state) => {
                state.status = 'loading';
                logger.info('Renaming file started');
            })
            .addCase(renameFile.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('File renaming succeeded');
            })
            .addCase(renameFile.rejected, (state, action) => {
                handleAPIError('File renaming failed', logger)(state, action);
            })

            .addCase(deleteDrawing.pending, (state) => {
                state.status = 'loading';
                logger.info('Deleting drawing started');
            })
            .addCase(deleteDrawing.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Drawing deletion succeeded');
            })
            .addCase(deleteDrawing.rejected, (state, action) => {
                handleAPIError('Drawing deletion failed', logger)(state, action);
            })

            .addCase(renameDrawing.pending, (state) => {
                state.status = 'loading';
                logger.info('Renaming drawing started');
            })
            .addCase(renameDrawing.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Drawing renaming succeeded');
            })
            .addCase(renameDrawing.rejected, (state, action) => {
                handleAPIError('Drawing renaming failed', logger)(state, action);
            })

            .addCase(deleteFolder.pending, (state) => {
                state.status = 'loading';
                logger.info('Deleting folder started');
            })
            .addCase(deleteFolder.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Folder deletion succeeded');
            })
            .addCase(deleteFolder.rejected, (state, action) => {
                handleAPIError('Folder deletion failed', logger)(state, action);
            })

            .addCase(renameFolder.pending, (state) => {
                state.status = 'loading';
                logger.info('Renaming folder started');
            })
            .addCase(renameFolder.fulfilled, (state, action: PayloadAction<{message: string}>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Folder renaming succeeded');
            })
            .addCase(renameFolder.rejected, (state, action) => {
                handleAPIError('Folder renaming failed', logger)(state, action);
            })
            .addCase(downloadFile.pending, (state) => {
                state.status = 'loading';
                logger.info('Downloading file started');
            })
            .addCase(downloadFile.fulfilled, (state, action: PayloadAction<{ blob: Blob, headers: any }>) => {
                state.status = 'succeeded';
                state.error = null;
                const { blob, headers } = action.payload;
                const contentDisposition = headers['content-disposition'];
                const fileName = getFileNameFromContentDisposition(contentDisposition);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                logger.info('File download succeeded');
            })
            .addCase(downloadFile.rejected, handleAPIError('File download failed', logger))
            
            .addCase(downloadDrawingFile.pending, (state) => {
                state.status = 'loading';
                logger.info('Downloading drawing file started');
            })
            .addCase(downloadDrawingFile.fulfilled, (state, action: PayloadAction<{ blob: Blob, headers: any }>) => {
                state.status = 'succeeded';
                state.error = null;
                const { blob, headers } = action.payload;
                const contentDisposition = headers['content-disposition'];
                const fileName = getFileNameFromContentDisposition(contentDisposition);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                logger.info('Drawing file download succeeded');
            })
            .addCase(downloadDrawingFile.rejected, handleAPIError('Drawing file download failed', logger));
    },
});

export default cloudSlice.reducer;

const getFileNameFromContentDisposition = (contentDisposition: string | null): string => {
    logger.debug('Content disposition', { contentDisposition });
    if (!contentDisposition) return "download";
    const fileNameMatch = contentDisposition.match(/filename="(.+?)"/);
    logger.debug('File name match', { fileNameMatch });
    return fileNameMatch ? fileNameMatch[1] : "download";
};