import axios from 'axios';
import createLogger from '../logger';

const logger = createLogger('api.ts');

const api = axios.create({
  baseURL: 'https://app.cadrak.tech', // https://ec2-16-16-103-22.eu-north-1.compute.amazonaws.com
  headers: {
    'Content-Type': 'application/json',
  },
});

// Log all requests
api.interceptors.request.use(request => {
  logger.info('Starting Request', request);
  return request;
});

// Log all responses
api.interceptors.response.use(response => {
  logger.info('Response:', response);
  return response;
}, error => {
  logger.warn('Response Error:', error);
  return Promise.reject(error);
});

// Helper function to set the authorization token in headers
export const setAuthToken = (token: string | null) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// User Management Endpoints
export const registerUserAPI = async (data: { username: string; email: string; password: string }) => {
  const response = await api.post('/user_management/register', data);
  return { data: response.data, message: response.data.message };
};

export const loginUserAPI = async (data: { email: string; password: string }) => {
  const response = await api.post('/user_management/login', data);
  return { data: response.data, message: response.data.message };
};

export const logoutUserAPI = async () => {
  const response = await api.post('/user_management/logout');
  return { data: response.data, message: response.data.message };
};

export const updateUserInformationAPI = async (data: { username?: string; email?: string; password?: string }) => {
  const response = await api.put('/user_management/update', data);
  return { data: response.data, message: response.data.message };
};

export const validateTokenAPI = async () => {
  const response = await api.get('/user_management/validate_token');
  return { data: response.data, message: response.data.message };
};

export const getUsersDetailsAPI = async ({ user_ids }: { user_ids: number[] }) => {
  const response = await api.post('/user_management/users/details', { user_ids });
  return { data: response.data, message: response.data.message };
};

// Folder Management Endpoints
export const createFolderAPI = async (arg: { name: string; parentId: number | null; isProject: boolean }) => {
  const response = await api.post('/file_management/folders/create', { name: arg.name, parent_id: arg.parentId, is_project: arg.isProject });
  return { data: response.data, message: response.data.message };
};

export const fetchFolderContentsAPI = async (folderId: number) => {
  const response = await api.get(`/file_management/folders/${folderId}`);
  return { data: response.data, message: response.data.message };
};

export const fetchHomeFolderAPI = async () => {
  const response = await api.get('/file_management/folders/home');
  return { data: response.data, message: response.data.message };
};

export const renameFolderAPI = async (arg: { folderId: number; newName: string }) => {
  const response = await api.put(`/file_management/folders/${arg.folderId}/rename`, { new_name: arg.newName });
  return { data: response.data, message: response.data.message };
};

export const deleteFolderAPI = async (folderId: number) => {
  const response = await api.delete(`/file_management/folders/${folderId}`);
  return { data: response.data, message: response.data.message };
};

export const getFolderHierarchyAPI = async (folderId: number) => {
  const response = await api.get(`/file_management/folders/${folderId}/hierarchy`);
  return { data: response.data.hierarchy, message: response.data.message };
};

// File Management Endpoints
export const uploadFileAPI = async (arg: { file: File; folderId: number }) => {
  const formData = new FormData();
  formData.append('file', arg.file);
  formData.append('folder_id', arg.folderId.toString());
  const response = await api.post('/file_management/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { data: response.data, message: response.data.message };
};

export const getFileMetadataAPI = async (fileId: number) => {
  const response = await api.get(`/file_management/files/${fileId}`);
  return { data: response.data, message: response.data.message };
};

export const renameFileAPI = async (arg: { fileId: number; newName: string }) => {
  const response = await api.put(`/file_management/files/${arg.fileId}/rename`, { new_name: arg.newName });
  return { data: response.data, message: response.data.message };
};

export const deleteFileAPI = async (fileId: number) => {
  const response = await api.delete(`/file_management/files/${fileId}`);
  return { data: response.data, message: response.data.message };
};

export const downloadFileAPI = async (fileId: number) => {
  try {
    const response = await api.get(`/file_management/files/${fileId}/download`, { responseType: 'blob' });
    return { data: { blob: response.data, headers: response.headers }, message: response.headers['content-disposition'] };
  } catch (error) {
    return { data: { blob: new Blob(), headers: {} }, message: 'Download failed' };
  }
};

// Drawing Management Endpoints
export const getDrawingFilesAPI = async (drawingId: number) => {
  const response = await api.get(`/file_management/drawings/${drawingId}`);
  return { data: response.data, message: response.data.message };
};

export const renameDrawingAPI = async (arg: { drawingId: number; newName: string }) => {
  const response = await api.put(`/file_management/drawings/${arg.drawingId}/rename`, { new_name: arg.newName });
  return { data: response.data, message: response.data.message };
};

export const deleteDrawingAPI = async (drawingId: number) => {
  const response = await api.delete(`/file_management/drawings/${drawingId}`);
  return { data: response.data, message: response.data.message };
};

export const deleteDrawingFileVersionAPI = async (drawingId: number, version: number) => {
  const response = await api.delete(`/file_management/drawings/${drawingId}/files/${version}`);
  return { data: response.data, message: response.data.message };
};

export const downloadDrawingFileAPI = async (arg: { drawingId: number; version: number }) => {
  try {
    const response = await api.get(`/file_management/drawings/${arg.drawingId}/download/${arg.version}`, { responseType: 'blob' });
    logger.info('API Response headers:', response.headers); // Add logging
    return { data: { blob: response.data, headers: response.headers }, message: response.headers['content-disposition'] }; // Return blob, headers, and message
  } catch (error) {
    logger.error('API Error:', error); // Add error logging
    return { data: { blob: new Blob(), headers: {} }, message: 'Download failed' }; // Return default headers and message
  }
};

// Parsing Endpoints
export const parseDrawingAPI = async (arg: { drawingId: number; version: number }) => {
  const response = await api.get(`/file_management/drawings/${arg.drawingId}/parse`, {
    params: { version: arg.version },
    responseType: 'text',
  });

  // Handle the response
  const fullData = response.data as string;
  const jsonObjects = fullData.split('---END---\n').filter(obj => obj.trim());

  // Parse the JSON objects
  const parsedData = jsonObjects.map(obj => {
    try {
      return JSON.parse(obj);
    } catch (e) {
      logger.error('Failed to parse JSON object:', obj, e);
      throw new Error('Invalid JSON format');
    }
  });

  const reportFile = parsedData.pop();

  return { data: { pages: parsedData, report: reportFile }, message: response.data.message };
};

export const convertToPngAPI = async (arg: { drawingId: number; version: number }) => {
  const response = await api.get(`/file_management/drawings/${arg.drawingId}/convert_to_png`, {
    params: { version: arg.version },
    responseType: 'blob',
  });
  return response.data;
};

// Annotation Management Endpoints
export const createAnnotationAPI = async (arg: { drawingId: number; content: string; parentId?: number; page: number; points: number[]; type: string; color: number }) => {
  const response = await api.post(`/file_management/drawings/${arg.drawingId}/annotations`, arg);
  return { data: response.data, message: response.data.message };
};

export const getAnnotationsAPI = async (drawingId: number) => {
  const response = await api.get(`/file_management/drawings/${drawingId}/annotations`);
  return { data: response.data, message: response.data.message };
};

export const updateAnnotationAPI = async (arg: { annotationId: number; content: string; drawingId: number; page: number; username: string; initials: string }) => {
  const response = await api.put(`/file_management/annotations/${arg.annotationId}`, arg);
  return { data: response.data, message: response.data.message };
};

export const deleteAnnotationAPI = async (annotationId: number, drawingId: number, page: number) => {
  const response = await api.delete(`/file_management/annotations/${annotationId}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    data: { drawingId, page }
  });
  return { data: response.data, message: response.data.message };
};

// Messenger related endpoints
export const fetchAllChatsAPI = async () => {
  const response = await api.get('/messenger/chats');
  return { data: response.data, message: response.data.message };
};

export const fetchMessagesAPI = async ({ chatId, page, perPage }: { chatId: number; page: number; perPage: number }) => {
  const response = await api.get(`/messenger/messages/${chatId}?page=${page}&per_page=${perPage}`);
  return { data: { chatId, ...response.data.data }, message: response.data.message };
};

export const sendMessageAPI = async ({ chatId, content }: { chatId: number; content: string }) => {
  const response = await api.post('/messenger/messages', { chat_id: chatId, content });
  return { data: response.data, message: response.data.message };
};

export const updateMessageAPI = async ({ messageId, content }: { messageId: number; content: string }) => {
  const response = await api.put(`/messenger/messages/${messageId}`, { content });
  return { data: response.data, message: response.data.message };
};

export const deleteMessageAPI = async ({ messageId }: { messageId: number }) => {
  const response = await api.delete(`/messenger/messages/${messageId}`);
  return { data: response.data, message: response.data.message };
};