import { message } from 'antd';
import { useCallback } from 'react';

type MessageType = 'success' | 'error' | 'info';

export const useGlobalMessages = () => {
  const showMessage = useCallback((type: MessageType, content: string) => {
    message[type]({
      content,
      style: {
        fontSize: '17.5px',
        padding: '12px 16px',
      },
    });
  }, []);

  return { showMessage };
};