import React, { useState, useEffect } from 'react';
import tigerTankImage from '../assets/images/tiger_tank.png';

const TigerTank: React.FC = () => {
  const [position, setPosition] = useState({ x: -100, y: window.innerHeight / 2 });
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(0);
  const [hue, setHue] = useState(0);

  useEffect(() => {
    setScale(1);

    const moveInterval = setInterval(() => {
      setPosition((prev) => {
        if (prev.x >= window.innerWidth) {
          clearInterval(moveInterval);
          setScale(0);
          return { x: -100, y: window.innerHeight / 2 };
        }
        return {
          x: prev.x + 15,
          y: prev.y + Math.sin(prev.x * 0.05) * 10,
        };
      });
    }, 30);

    const rotateInterval = setInterval(() => {
      setRotation((prev) => (prev + 20) % 360);
    }, 50);

    const hueInterval = setInterval(() => {
      setHue((prev) => (prev + 10) % 360);
    }, 50);

    return () => {
      clearInterval(moveInterval);
      clearInterval(rotateInterval);
      clearInterval(hueInterval);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
        zIndex: 9999,
        transition: 'transform 0.1s ease-in-out',
        transform: `rotate(${rotation}deg) scale(${scale})`,
        filter: `hue-rotate(${hue}deg) contrast(150%) brightness(120%)`,
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          src={tigerTankImage}
          alt="Tiger Tank"
          style={{ width: '150px', height: 'auto' }}
        />
        {/* Chaotic trail */}
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              top: '50%',
              right: '100%',
              width: `${50 + index * 10}px`,
              height: `${4 + index * 2}px`,
              background: `hsl(${index * 36}, 100%, 50%)`,
              transform: `translateY(-50%) translateX(${index * 15}px) rotate(${index * 20}deg)`,
              opacity: 0.7,
              boxShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
              borderRadius: '50%',
            }}
          />
        ))}
        {/* Comic-style "BOOM" text */}
        <div
          style={{
            position: 'absolute',
            top: '-50px',
            left: '50%',
            transform: 'translateX(-50%) rotate(-20deg)',
            fontSize: '40px',
            fontWeight: 'bold',
            color: 'yellow',
            textShadow: '2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000',
            animation: 'boom 0.5s infinite alternate',
          }}
        >
          BOOM!
        </div>
      </div>
      <style>
        {`
          @keyframes boom {
            0% { transform: translateX(-50%) rotate(-20deg) scale(1); }
            100% { transform: translateX(-50%) rotate(-20deg) scale(1.5); }
          }
        `}
      </style>
    </div>
  );
};

export default TigerTank;