import { useDispatch, useSelector } from 'react-redux';
import { validateToken, register, login, logout, updateUser, fetchUsersDetails } from '../store/slices/authSlice';
import { setAuthToken } from '../services/api';
import { AppDispatch, RootState } from '../store/store';
import createLogger from '../logger';
import { useState, useCallback, useEffect, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useGlobalMessages } from './useGlobalMessages';

const logger = createLogger('useAuthHooks.ts');

const useAuthHooks = () => {
    const dispatch: AppDispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth);
    const [isTokenValidation, setIsTokenValidation] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);
    const { showMessage } = useGlobalMessages();

    const validateTokenHook = useCallback(async () => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            logger.info('Token validation called');
            setAuthToken(token);
            try {
                const result = await dispatch(validateToken()).unwrap();
                setIsTokenValidation(true);
            } catch (error) {
            }
        } else {
            logger.info('No token found in localStorage');
        }
        setIsTokenValidated(true);
    }, [dispatch]);

    const registerUserHook = async (username: string, email: string, password: string): Promise<boolean> => {
        logger.info('Register user called');
        try {
            const result = await dispatch(register({ username, email, password })).unwrap();
            setIsTokenValidation(false);
            await dispatch(validateToken());
            showMessage('success', result.message);
            return true;
        } catch (error) {
            logger.error('Registration failed', error);
            showMessage('error', 'Registration failed. Please try again.');
            return false;
        }
    };

    const loginUserHook = async (email: string, password: string): Promise<boolean> => {
        logger.info('Login user called');
        try {
            const result = await dispatch(login({ email, password })).unwrap();
            setIsTokenValidation(false);
            await dispatch(validateToken());
            showMessage('success', result.message);
            return true;
        } catch (error) {
            logger.error('Login failed', error);
            showMessage('error', 'Login failed. Please check your credentials.');
            return false;
        }
    };

    const logoutUserHook = async () => {
        logger.info('Logout user called');
        await dispatch(logout());
        showMessage('success', 'Logout successful');
        setIsTokenValidated(false);
        setIsTokenValidation(false);
        localStorage.removeItem('jwtToken');
    };

    const updateUserInfoHook = async (username?: string, email?: string, password?: string) => {
        logger.info('Update user information called');
        await dispatch(updateUser({ username, email, password }));
        await dispatch(validateToken());
    };

    const fetchUsersDetailsHook = async (userIds: number[]) => {
        logger.info('Fetch users details called');
        try {
            const resultAction = await dispatch(fetchUsersDetails({ user_ids: userIds }));
            unwrapResult(resultAction);
        } catch (error) {
            logger.error('Fetching users details failed', error);
        }
    };

    return {
        validate: validateTokenHook,
        registerUser: registerUserHook,
        loginUser: loginUserHook,
        logoutUser: logoutUserHook,
        updateUserInfo: updateUserInfoHook,
        fetchUsersDetails: fetchUsersDetailsHook,
        auth,
        isTokenValidation,
        isTokenValidated,
    };
};

export default useAuthHooks;