import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { parseDrawingAPI, createAnnotationAPI, getAnnotationsAPI, updateAnnotationAPI, deleteAnnotationAPI, convertToPngAPI } from '../../services/api';
import createLogger from '../../logger';

const logger = createLogger('parsingSlice.ts');

interface FolderError {
    message: string;
}

export interface SerializableAnnotation {
    annotation: any;
    id: number;
    type: string;
    points: number[];
    color: number;
    page: number;
    content: string;
    username: string;
    initials: string;
}

interface ParsingState {
    parsedData: { pages: any[]; report: any } | null;
    currentPage: number;
    annotations: { [drawingId: number]: { [page: number]: SerializableAnnotation[] } };
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    currentFileId: number | null;
    pngBlob: Blob | null;
}

const initialState: ParsingState = {
    currentPage: 1,
    parsedData: null,
    annotations: {},
    status: 'idle',
    error: null,
    currentFileId: null,
    pngBlob: null,
};

export const parseDrawing = createAsyncThunk<
    { pages: any[], report: any },
    { drawingId: number, version: number },
    { rejectValue: FolderError }
>(
    'parsing/parseDrawing',
    async ({ drawingId, version }, { rejectWithValue }) => {
        try {
            const { data } = await parseDrawingAPI({ drawingId, version });
            logger.info('Response succeeded', data);
            return data;
        } catch (error) {
            const err = error as { response: { data: FolderError } };
            return rejectWithValue(err.response.data);
        }
    }
);

export const convertToPng = createAsyncThunk<
    Blob,
    { drawingId: number; version: number },
    { rejectValue: FolderError }
>(
    'parsing/convertToPng',
    async ({ drawingId, version }, { rejectWithValue }) => {
        try {
            const blob = await convertToPngAPI({ drawingId, version });
            return blob;
        } catch (error) {
            const err = error as { response: { data: FolderError } };
            return rejectWithValue(err.response.data);
        }
    }
);

export const createAnnotation = createAsyncThunk<
    SerializableAnnotation,
    { drawingId: number; content: string; parentId?: number; page: number; points: number[]; type: string; color: number },
    { rejectValue: FolderError }
>(
    'parsing/createAnnotation',
    async ({ drawingId, content, parentId, page, points, type, color }, { rejectWithValue }) => {
        try {
            // @ts-ignore
            const { data } = await createAnnotationAPI({ drawingId, content, parentId, page, points, type, color });
            logger.info('Annotation created successfully', data);
            return data.annotation;
        } catch (error) {
            const err = error as { response: { data: FolderError } };
            return rejectWithValue(err.response.data);
        }
    }
);


export const editAnnotation = createAsyncThunk<
    SerializableAnnotation & { drawingId: number; page: number },
    { annotationId: number; content: string; drawingId: number; page: number; username: string; initials: string },
    { rejectValue: FolderError }
>(
    'parsing/editAnnotation',
    async ({ annotationId, content, drawingId, page, username, initials }, { rejectWithValue }) => {
        try {
            // @ts-ignore
            const { data } = await updateAnnotationAPI({ annotationId, content, drawingId, page, username, initials });
            logger.info('Annotation edited successfully', data);
            return { ...data.annotation, drawingId, page, username, initials };
        } catch (error) {
            const err = error as { response: { data: FolderError } };
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteAnnotation = createAsyncThunk<
    { annotationId: number; drawingId: number; page: number },
    { annotationId: number; drawingId: number; page: number },
    { rejectValue: FolderError }
>(
    'parsing/deleteAnnotation',
    async ({ annotationId, drawingId, page }, { rejectWithValue }) => {
        try {
            await deleteAnnotationAPI(annotationId, drawingId, page);
            logger.info('Annotation deleted successfully');
            return { annotationId, drawingId, page };
        } catch (error) {
            const err = error as { response: { data: FolderError } };
            return rejectWithValue(err.response.data);
        }
    }
);

export const removeAllAnnotations = createAsyncThunk(
    'parsing/removeAllAnnotations',
    async ({ drawingId, page }: { drawingId: number, page: number }) => {
      // Here you would typically make an API call to remove all annotations
      // For now, we'll just return the drawingId and page
      return { drawingId, page };
    }
  );

export const fetchAnnotations = createAsyncThunk<
  { [page: number]: SerializableAnnotation[] },
  number,
  { rejectValue: FolderError }
>(
  'parsing/fetchAnnotations',
  async (drawingId, { rejectWithValue }) => {
    try {
      const { data } = await getAnnotationsAPI(drawingId);
      const annotationsByPage: { [page: number]: SerializableAnnotation[] } = {};
      data.annotations.forEach((annotation: SerializableAnnotation) => {
        if (!annotationsByPage[annotation.page]) {
          annotationsByPage[annotation.page] = [];
        }
        annotationsByPage[annotation.page].push(annotation);
      });
      return annotationsByPage;
    } catch (error) {
      const err = error as { response: { data: FolderError } };
      return rejectWithValue(err.response.data);
    }
  }
);



const parsingSlice = createSlice({
    name: 'parsing',
    initialState,
    reducers: {
        setCurrentFileId: (state, action: PayloadAction<number | null>) => {
            state.currentFileId = action.payload;
            logger.info('Current file id set', action.payload);
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
            logger.info('Current page set', action.payload);
        },
        addAnnotation: (state, action: PayloadAction<{ drawingId: number; page: number; annotation: SerializableAnnotation }>) => {
            const { drawingId, page, annotation } = action.payload;
            if (!state.annotations[drawingId]) {
                state.annotations[drawingId] = {};
            }
            if (!state.annotations[drawingId][page]) {
                state.annotations[drawingId][page] = [];
            }
            state.annotations[drawingId][page].push(annotation);
        },
        updateAnnotation: (state, action: PayloadAction<{ drawingId: number; page: number; annotationId: number; updates: Partial<SerializableAnnotation> }>) => {
            const { drawingId, page, annotationId, updates } = action.payload;
            if (state.annotations[drawingId] && state.annotations[drawingId][page]) {
                const annotationIndex = state.annotations[drawingId][page].findIndex(a => a.id === annotationId);
                if (annotationIndex !== -1) {
                    state.annotations[drawingId][page][annotationIndex] = {
                        ...state.annotations[drawingId][page][annotationIndex],
                        ...updates
                    };
                    console.log("Updated state:", state.annotations[drawingId][page][annotationIndex]);
                }
            }
        },
        removeAnnotation: (state, action: PayloadAction<{ drawingId: number; page: number; annotationId: number }>) => {
            const { drawingId, page, annotationId } = action.payload;
            if (state.annotations[drawingId]?.[page]) {
                state.annotations[drawingId][page] = state.annotations[drawingId][page].filter(a => a.id !== annotationId);
                console.log("The annotation to be removed: ", state.annotations[drawingId][page]);
            }
        },
        resetParsingState: (state, action: PayloadAction<{ keepFileId?: boolean, keepParsedData?: boolean }>) => {
            const { keepFileId, keepParsedData } = action.payload;
            const newState = { ...initialState };
            if (keepFileId) {
                newState.currentFileId = state.currentFileId;
            }
            if (keepParsedData) {
                newState.parsedData = state.parsedData;
            }
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(parseDrawing.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(parseDrawing.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.parsedData = action.payload;
                state.error = null;
            })
            .addCase(parseDrawing.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Unknown error';
            })
            .addCase(createAnnotation.fulfilled, (state, action) => {
                const { drawingId, page } = action.meta.arg;
                if (!state.annotations[drawingId]) {
                    state.annotations[drawingId] = {};
                }
                if (!state.annotations[drawingId][page]) {
                    state.annotations[drawingId][page] = [];
                }
                const newAnnotation = {
                    ...action.payload,
                    id: action.payload.id // Use the ID from the payload or nested annotation object
                };
                console.log("The id: ", action.payload.id)
                state.annotations[drawingId][page].push(newAnnotation);
            })
            .addCase(editAnnotation.fulfilled, (state, action) => {
                const { drawingId, page, id } = action.payload;
                if (state.annotations[drawingId] && state.annotations[drawingId][page]) {
                    const index = state.annotations[drawingId][page].findIndex(a => a.id === id);
                    if (index !== -1) {
                        state.annotations[drawingId][page][index] = action.payload;
                    }
                }
            })
            .addCase(deleteAnnotation.fulfilled, (state, action) => {
                const { drawingId, page, annotationId } = action.payload;
                if (state.annotations[drawingId] && state.annotations[drawingId][page]) {
                    state.annotations[drawingId][page] = state.annotations[drawingId][page].filter(a => a.id !== annotationId);
                }
            })
            .addCase(fetchAnnotations.fulfilled, (state, action) => {
                const drawingId = state.currentFileId;
                if (drawingId !== null) {
                  state.annotations[drawingId] = action.payload;
                }
              })
            .addCase(convertToPng.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(convertToPng.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.pngBlob = action.payload;
                state.error = null;
            })
            .addCase(convertToPng.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Unknown error';
            })
            .addCase(removeAllAnnotations.fulfilled, (state, action) => {
                const { drawingId, page } = action.payload;
                if (state.annotations[drawingId]) {
                  state.annotations[drawingId][page] = [];
                }
            });
    },
});

export const { setCurrentFileId, setCurrentPage, addAnnotation, updateAnnotation, removeAnnotation, resetParsingState } = parsingSlice.actions;

export default parsingSlice.reducer;