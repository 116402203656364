// src/components/common/CustomLoading.tsx
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './CustomLoading.css';

interface CustomLoadingProps {
    loading: boolean;
    children: React.ReactNode;
}

const CustomLoading: React.FC<CustomLoadingProps> = ({ loading, children }) => {
    const [showLoading, setShowLoading] = useState(loading);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (loading) {
            setShowLoading(true);
        } else {
            timer = setTimeout(() => setShowLoading(false), 100);
        }
        return () => clearTimeout(timer);
    }, [loading]);

    return (
        <div className={`custom-loading-container ${loading ? 'loading' : ''}`}>
            {showLoading && (
                <div className="loading-overlay">
                    <Spin indicator={<LoadingOutlined spin style={{ fontSize: '96px', color: '#39A0ED' }} />} />
                </div>
            )}
            {children}
        </div>
    );
};

export default CustomLoading;