// src/declaration.d.ts
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <App/>
);
