// src/store/slices/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../../services/api';
import { User } from '../models';
import { handleAPIError, createThunkTemplate } from '../../services/apiUtils';
import createLogger from '../../logger';
import { setAuthToken } from '../../services/api';

const logger = createLogger('authSlice.ts');

interface AuthState {
    user: User | null;
    token: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    users: User[];
}

const initialState: AuthState = {
    user: null,
    token: null,
    status: 'idle',
    error: null,
    users: [],
};

// Async thunks
export const validateToken = createThunkTemplate<{ user: User; message: string }, void>(
    'auth/validateToken',
    api.validateTokenAPI
);

export const register = createThunkTemplate<{ access_token: string; message: string }, { username: string; email: string; password: string }>(
    'auth/register',
    api.registerUserAPI
);

export const login = createThunkTemplate<{ access_token: string; message: string }, { email: string; password: string }>(
    'auth/login',
    api.loginUserAPI
);

export const logout = createThunkTemplate<{ message: string }, void>(
    'auth/logout',
    api.logoutUserAPI
);

export const updateUser = createThunkTemplate<{ message: string }, { username?: string; email?: string; password?: string }>(
    'auth/updateUser',
    api.updateUserInformationAPI
);

export const fetchUsersDetails = createThunkTemplate<
    { message: string; status: number; users: { [key: string]: User } },
    { user_ids: number[] }
>(
    'auth/fetchUsersDetails',
    api.getUsersDetailsAPI
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateToken.pending, (state) => {
                state.status = 'loading';
                logger.info('Token validation started');
            })
            .addCase(validateToken.fulfilled, (state, action: PayloadAction<{ user: User; message: string }>) => {
                state.user = action.payload.user;
                state.token = localStorage.getItem('jwtToken');
                state.status = 'succeeded';
                state.error = null;
                logger.info('Token validation succeeded', action.payload);
            })
            .addCase(validateToken.rejected, handleAPIError('Token validation failed', logger))
            .addCase(register.pending, (state) => {
                state.status = 'loading';
                logger.info('User registration started');
            })
            .addCase(register.fulfilled, (state, action: PayloadAction<{ access_token: string; message: string }>) => {
                state.token = action.payload.access_token;
                state.status = 'succeeded';
                state.error = null;
                localStorage.setItem('jwtToken', action.payload.access_token);
                setAuthToken(action.payload.access_token);
                logger.info('User registration succeeded', action.payload);
            })
            .addCase(register.rejected, (state, action) => {
                handleAPIError('User registration failed', logger)(state, action);
            })
            .addCase(login.pending, (state) => {
                state.status = 'loading';
                logger.info('User login started');
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<{ access_token: string; message: string }>) => {
                state.token = action.payload.access_token;
                state.status = 'succeeded';
                state.error = null;
                localStorage.setItem('jwtToken', action.payload.access_token);
                setAuthToken(action.payload.access_token);
                logger.info('User login succeeded', action.payload);
            })
            .addCase(login.rejected, (state, action) => {
                handleAPIError('User login failed', logger)(state, action);
            })
            .addCase(logout.pending, (state) => {
                state.status = 'loading';
                logger.info('User logout started');
            })
            .addCase(logout.fulfilled, (state, action: PayloadAction<{ message: string }>) => {
                state.user = null;
                state.token = null;
                state.status = 'succeeded';
                state.error = null;
                localStorage.removeItem('jwtToken');
                setAuthToken(null);
                logger.info('User logout succeeded', action.payload);
            })
            .addCase(logout.rejected, handleAPIError('User logout failed', logger))
            .addCase(updateUser.pending, (state) => {
                state.status = 'loading';
                logger.info('User information update started');
            })
            .addCase(updateUser.fulfilled, (state, action: PayloadAction<{ message: string }>) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('User information update succeeded', action.payload);
            })
            .addCase(updateUser.rejected, handleAPIError('User information update failed', logger))
            .addCase(fetchUsersDetails.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching users details started');
              })
              .addCase(fetchUsersDetails.fulfilled, (state, action: PayloadAction<{ message: string; status: number; users: { [key: string]: User } }>) => {
                state.status = 'succeeded';
                state.error = null;
                state.users = Object.values(action.payload.users);
                logger.info('Fetching users details succeeded', action.payload);
              })
              .addCase(fetchUsersDetails.rejected, handleAPIError('Fetching users details failed', logger));
          },
    });
    
export default authSlice.reducer;