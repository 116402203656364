import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lines: []
};

const linesSlice = createSlice({
    name: 'lines',
    initialState,
    reducers: {
        addLine: (state, action) => {
            state.lines.push(action.payload);
        },
        updateLastLine: (state, action) => {
            const { index, points } = action.payload;
            state.lines[index].points = points;
        },
        clearLines: (state) => {
            state.lines = [];
        }
    }
});

export const { addLine, updateLastLine, clearLines } = linesSlice.actions;

export default linesSlice.reducer;
