// Route Paths
export const ROUTE_DEFAULT = "/";
export const ROUTE_AUTH = "/auth/*";
export const ROUTE_LOGIN = "/auth/login";
export const ROUTE_REGISTER = "/auth/register";
export const ROUTE_HOME = "/home";
export const ROUTE_VIEWPORT = "/viewport";

// Image Paths
export const IMAGE_DWG_ICON = "assets/images/icons/dwg_icon.png";
export const IMAGE_DXF_ICON = "assets/images/icons/dxf_icon.png";
export const IMAGE_STEP_ICON = "assets/images/icons/step_icon.png";
export const IMAGE_CADRAK_LOGO = "assets/images/cadrak_logo.png";
export const IMAGE_CADRAK_LOGO_TEXT = "assets/images/cadrak_logo_text.png";


// Icon paths
export const ICON_PEN = "/assets/icons/tools/pen.svg";
export const ICON_CIRCLE = "/assets/icons/tools/circle.svg";
export const ICON_RECTANGLE = "/assets/icons/tools/rectangle.svg";
export const ICON_LINE = "/assets/icons/tools/line.svg";
export const ICON_TEXT = "/assets/icons/tools/text.svg";
export const ICON_ERASER = "/assets/icons/tools/eraser.svg";


export const ICON_EYE_CLOSED = "/assets/icons/tools/eye_closed.svg";
export const ICON_EYE_OPEN = "/assets/icons/tools/eye_open.svg";
export const ICON_TABLE = "/assets/icons/tools/table.svg";
export const ICON_MAGIC_MOUSE = "/assets/icons/tools/magic_mouse.svg";

export const ICON_ROTATE = "/assets/icons/tools/rotate.svg";
export const ICON_ZOOM_IN = "/assets/icons/tools/zoom_in.svg";
export const ICON_ZOOM_OUT = "/assets/icons/tools/zoom_out.svg";

export const ICON_MESSENGER = '/assets/icons/tools/messenger.svg';
export const ICON_SHARE = '/assets/icons/tools/share.svg';
export const ICON_PRINT = '/assets/icons/tools/print.svg';
export const ICON_DOWNLOAD = '/assets/icons/tools/download.svg';
export const ICON_ADD_FOLDER = '/assets/icons/tools/add_folder.svg';
export const ICON_UPLOAD_A_FILE = '/assets/icons/tools/upload_a_file.svg';
export const ICON_SHARED_WITH_ME = '/assets/icons/tools/shared_with_me.svg';
export const ICON_BIN = '/assets/icons/tools/bin.svg';


export const ICON_PROJECT = '/assets/icons/tools/project.svg'




