// src/utils/apiUtils.ts
import { createAsyncThunk } from '@reduxjs/toolkit';

export const handleAPIError = (errorMessage: string, logger: any) => (
    state: any,
    action: any
) => {
    state.status = 'failed';
    state.error = action.payload?.message || 'Unknown error';
    logger.warn(errorMessage, action.payload);
};

export const createThunkTemplate = <Returned, ThunkArg>(
    actionType: string,
    apiFunction: (arg: ThunkArg) => Promise<{ data: Returned }>
) =>
    createAsyncThunk(
        actionType,
        async (arg: ThunkArg, { rejectWithValue }) => {
            try {
                const response = await apiFunction(arg);
                return response.data;
            } catch (error) {
                const err = error as { response: { data: any } };
                return rejectWithValue(err.response.data);
            }
        }
    );

