// src/App.tsx
import React, { useEffect, useRef, Suspense, lazy, useState, useCallback } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import store from './store/store';
import './App.css';
import useAuthHooks from './hooks/useAuthHooks';
import { ROUTE_HOME, ROUTE_LOGIN, ROUTE_REGISTER, ROUTE_AUTH, ROUTE_VIEWPORT, ROUTE_DEFAULT } from './constants';
import CustomLoading from './components/common/CustomLoading';
import { resetParsingState, setCurrentFileId } from './store/slices/parsingSlice';
import { RootState } from './store/store';
import TigerTank from './assets/TigerTank';

const AuthPage = lazy(() => import('./pages/AuthPage/AuthPage'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const Viewport = lazy(() => import('./pages/Viewport/Viewport'));

const AppContent = () => {
    const { validate, auth, isTokenValidation, isTokenValidated } = useAuthHooks();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const hasValidated = useRef(false);
    const [showViewport, setShowViewport] = useState(false);
    const currentFileId = useSelector((state: RootState) => state.parsing.currentFileId);
    const [showTigerTank, setShowTigerTank] = useState(false);
    const spaceCount = useRef(0);
    const lastKeyTime = useRef(0);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        if (!hasValidated.current) {
            validate();
            hasValidated.current = true;
        }
    }, [validate]);

    useEffect(() => {
        if (isTokenValidated) {
            if (auth.status === 'failed' || !auth.token) {
                if (window.location.pathname !== ROUTE_REGISTER) {
                    navigate(ROUTE_LOGIN);
                }
            } else if (auth.status === 'succeeded') {
                if (isTokenValidation && (window.location.pathname === ROUTE_LOGIN || window.location.pathname === ROUTE_REGISTER)) {
                    navigate(ROUTE_HOME);
                }
            }
        }
    }, [auth, isTokenValidation, isTokenValidated, navigate]);

    const cleanupViewport = useCallback(() => {
        if (window.gc) window.gc();
        if (window.caches) {
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
            });
        }
        dispatch(resetParsingState({
            keepFileId: false,
            keepParsedData: false
        }));
    }, [dispatch]);

    useEffect(() => {
        const isEnteringViewport = location.pathname === ROUTE_VIEWPORT;
        const isLeavingViewport = !isEnteringViewport && showViewport;

        setShowViewport(isEnteringViewport);

        if (isLeavingViewport) {
            cleanupViewport();
        }

        if (isEnteringViewport && currentFileId) {
            dispatch(setCurrentFileId(currentFileId));
        }

    }, [location, showViewport, cleanupViewport, dispatch, currentFileId]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'Space') {
                const currentTime = new Date().getTime();
                if (currentTime - lastKeyTime.current < 1000) {
                    spaceCount.current += 1;
                } else {
                    spaceCount.current = 1;
                }
                lastKeyTime.current = currentTime;

                if (spaceCount.current === 5) {
                    triggerTigerTankMeme();
                    spaceCount.current = 0;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const triggerTigerTankMeme = () => {
        const appearDisappearCycle = () => {
            setShowTigerTank(true);
            const disappearTime = Math.random() * 3000 + 2000; // Random time between 2-5 seconds
            setTimeout(() => {
                setShowTigerTank(false);
                const reappearTime = Math.random() * 5000 + 3000; // Random time between 3-8 seconds
                setTimeout(appearDisappearCycle, reappearTime);
            }, disappearTime);
        };

        appearDisappearCycle();
    };

    return (
        <div className="app">
            <CustomLoading loading={pageLoading}>
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route path={ROUTE_HOME} element={<HomePage setPageLoading={setPageLoading} />} />
                        <Route path={ROUTE_AUTH} element={<AuthPage setPageLoading={setPageLoading} />} />
                        <Route path={ROUTE_VIEWPORT} element={showViewport ? <Viewport setPageLoading={setPageLoading} /> : null} />
                        <Route path={ROUTE_DEFAULT} element={<Navigate to={ROUTE_LOGIN} />} />
                    </Routes>
                </Suspense>
            </CustomLoading>
            {showTigerTank && <TigerTank />}
        </div>
    );
};

const App = () => (
    <Provider store={store}>
            <Router>
                <AppContent />
            </Router>
    </Provider>
);

export default App;