// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import cloudReducer from './slices/cloudSlice';
import lineReducer from './slices/linesSlice';
import parsingReducer from './slices/parsingSlice';
import messengerReducer from './slices/messengerSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        cloud: cloudReducer,
        lines: lineReducer,
        parsing: parsingReducer,
        messenger: messengerReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;