import chalk from 'chalk';

type LogLevel = 'debug' | 'log' | 'info' | 'warn' | 'error';

interface LoggerOptions {
  minLevel?: LogLevel;
  timestampFormat?: string;
}

const logLevels: Record<LogLevel, number> = {
  debug: 0,
  log: 1,
  info: 2,
  warn: 3,
  error: 4,
};

const createLogger = (scriptName: string, options: LoggerOptions = {}) => {
  const { minLevel = 'debug', timestampFormat = 'YYYY-MM-DD HH:mm:ss' } = options;

  const formatTimestamp = () => {
    return new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  const log = (level: LogLevel, color: chalk.ChalkFunction, ...args: any[]) => {
    if (logLevels[level] >= logLevels[minLevel]) {
      console[level](
        color(`[${level.toUpperCase()}] ${formatTimestamp()} [${scriptName}]:`),
        ...args
      );
    }
  };

  return {
    debug: (...args: any[]) => log('debug', chalk.gray, ...args),
    log: (...args: any[]) => log('log', chalk.white, ...args),
    info: (...args: any[]) => log('info', chalk.blue, ...args),
    warn: (...args: any[]) => log('warn', chalk.yellow, ...args),
    error: (...args: any[]) => log('error', chalk.red, ...args),
  };
};

export default createLogger;