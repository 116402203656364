import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../../services/api';
import { handleAPIError, createThunkTemplate } from '../../services/apiUtils';
import { Chat, Message } from '../models';
import createLogger from '../../logger';

const logger = createLogger('messengerSlice.ts');

interface MessengerState {
    chats: Chat[];
    messages: { [chatId: number]: Message[] };
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: MessengerState = {
    chats: [],
    messages: {},
    status: 'idle',
    error: null,
};

export const fetchChats = createThunkTemplate<{ chats: Chat[] }, void>(
    'messenger/fetchChats',
    api.fetchAllChatsAPI
);

export const fetchMessages = createThunkTemplate<{ chatId: number; messages: Message[]; total: number; pages: number; current_page: number }, { chatId: number; page: number; perPage: number }>(
    'messenger/fetchMessages',
    api.fetchMessagesAPI
);

export const sendMessage = createThunkTemplate<void, { chatId: number; content: string }>(
    'messenger/sendMessage',
    api.sendMessageAPI
);

const messengerSlice = createSlice({
    name: 'messenger',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChats.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching chats started');
            })
            .addCase(fetchChats.fulfilled, (state, action: PayloadAction<{ chats: Chat[] }>) => {
                state.status = 'succeeded';
                state.chats = action.payload.chats;
                state.error = null;
                logger.info('Fetching chats succeeded', action.payload);
            })
            .addCase(fetchChats.rejected, handleAPIError('Fetching chats failed', logger))
            .addCase(fetchMessages.pending, (state) => {
                state.status = 'loading';
                logger.info('Fetching messages started');
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { chatId, messages } = action.payload;
                state.messages[chatId] = messages;
                state.error = null;
                logger.info('Fetching messages succeeded', action.payload);
            })
            .addCase(fetchMessages.rejected, handleAPIError('Fetching messages failed', logger))
            .addCase(sendMessage.pending, (state) => {
                state.status = 'loading';
                logger.info('Sending message started');
            })
            .addCase(sendMessage.fulfilled, (state) => {
                state.status = 'succeeded';
                state.error = null;
                logger.info('Sending message succeeded');
            })
            .addCase(sendMessage.rejected, handleAPIError('Sending message failed', logger));
    },
});

export default messengerSlice.reducer;